Vue.component('ecofriendlyListComponent', {
    data: function () {
        return {
            viewmode: 'module',
            itemsFlag:false,
            orderSelected:null,
            ecofriendlyList : []
        }
    },
    watch: {
        '$store.state.appState':'fetchData',
        '$store.state.ecofriendlyList':'fetchData',
        '$store.state.orderBy':'fetchData',
        '$store.state.orderDirection':'fetchData'
    },
    computed:{
        initItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset;
        },
        lastItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
        },
        moduleObject: function () {
            return {
                'green-text': this.viewmode == 'module'
            }
        },
        listObject: function () {
            return {
                'green-text': this.viewmode == 'list'
            }
        }
    },
    methods:{
        fetchData:function(){
            if(!this.orderSelected)
                for(let orderField of this.$store.getters.getSetting.ItemOrdered){
                    if(orderField.fields.ByDefault){
                        this.orderSelected = orderField.fields.internalId
                    }
                }
            this.ecofriendlyList=this.$store.getters.getEcofriendlyItems
            this.ecofriendlyList = __lodash__.orderBy(this.ecofriendlyList, this.$store.getters.getOrderBy,this.$store.getters.getOrderDirection);
        },
        openItemView:function(item){
            if(item)
                this.$router.push(`/view/${item.Code}`);
        },
        reorderItems: function(){
            this.$store.dispatch('reorderItems',this.orderSelected);
        },
        getItemByCode:function(itemCode){
            return this.$store.getters.getItemByCode(itemCode);
        },
    },
    mounted:function(){
        this.fetchData()
    },
    template: `<div class="produktliste">
                    <div class="header-produktliste">
                        <h4>{{tr('Eco Friendly')}} ({{ecofriendlyList.length}})</h4>
                        <div class="items-options">
                            <div class="order-options">
                                <span>{{tr('Order by')}}</span>
                                <select id="soflow" v-model="orderSelected" class="minimal browser-default" @change="reorderItems">
                                    <option disabled value="">{{tr('Select One')}}</option>
                                    <template v-for="orderField of $store.getters.getSetting.ItemOrdered">
                                        <option :value="orderField.fields.internalId" :selected="orderField==orderSelected">{{tr(orderField.fields.ItemField)}}({{orderField.fields.OrderDirection}})</option>
                                    </template>
                                </select>
                            </div>
                            <div class="view-options">
                                <span @click="viewmode='module'" v-bind:class="moduleObject">
                                    <span>Mosaico</span>
                                    <i class="fas fa-th-large fa-1x"></i>
                                    <!--<img src="../static/ecommerce/assets/img/mosaico.jpg" width="20" height="22" alt="">-->
                                </span>
                                <span @click="viewmode='list'" v-bind:class="listObject">
                                    <span >Lista</span>
                                    <i class="fas fa-list-alt fa-1x"></i>
                                    <!--<img src="../static/ecommerce/assets/img/list.jpg" width="21" height="22" alt="">-->
                                </span>
                            </div>
                            <template v-if="ecofriendlyList.length>0">
                                <paginationComponent skey="ecofriendly-pagitation-top" currentView="ecofriendly" :items="ecofriendlyList" v-if="ecofriendlyList.length>0"></paginationComponent>
                            </template>
                        </div>
                        <div class="produktliste">
                            <template v-if="$store.getters.getAppState!='ready'">
                                    <template v-if="ecofriendlyList.length==0">
                                        <div class="container center section white" >
                                            <h3>{{tr('Please wait. Loading ...')}}</h3>
                                        </div>
                                    </template>
                             </template>
                            <template v-else-if="ecofriendlyList.length>0">
                                <template v-for="item in ecofriendlyList.slice(initItem,lastItem)">
                                    <div :class="{col:true, 's12': viewmode=='list', 's6 m6 l3': viewmode=='module'}" >
                                          <template v-if="viewmode=='list'">
                                              <itemViewListComponent :item="item" ></itemViewListComponent>
                                          </template>
                                          <template v-else>
                                              <itemViewModuleComponent :item="item" ></itemViewModuleComponent>
                                          </template>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="ecofriendlyList.length==0">
                                    <div class="container center section white" >
                                        <h3>{{tr('No items was found')}}</h3>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="items-options">
                            <template v-if="ecofriendlyList.length>0">
                                <paginationComponent key="ecofriendly-pagitation-bottom"  currentView="ecofriendly" :items="ecofriendlyList" v-if="ecofriendlyList.length>0"></paginationComponent>
                            </template>
                        </div>
                    </div>
                </div>`
});

Vue.component('ecofriendlyComponent', {
    methods:{
        goBack:function(){
            this.$router.go(-1);
        }
    },
    template: `<div class="items-view">
                  <changePasswordModalComponent></changePasswordModalComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState=='ready'">
                                <!--<registerComponent></registerComponent>-->
                                <loginComponent></loginComponent>
                              <div class="row">
                                  <div class="left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  </div>                                
                                  <div class="col m8 s12">
                                      <ecofriendlyListComponent></ecofriendlyListComponent>
                                  </div>
                                  <div class="col m2 s12 sticky-component right">
                                      <userSectionComponent></userSectionComponent>
                                      <carComponent></carComponent>
                                  </div>
                              </div>

                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`
});

